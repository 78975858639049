import React, { useCallback, useEffect, useRef } from "react";
import styles from "./VideoBlock.module.scss";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { BASE_STORE_URL } from "../../../../api/global";

const getVideoUrl = (isMobile: boolean, size: 3 | 6) => {
  return `${BASE_STORE_URL}/store/videos/indexpage-${
    isMobile ? "mobile" : "desktop"
  }-${size}mb.mp4`;
};

export const VideoBlock = () => {
  const { isMobile } = useWindowSize(768);
  const ref = useRef<HTMLDivElement>(null);
  const setSource = useCallback(() => {
    if (ref.current) {
      const video = ref.current.querySelector("video");
      const source = video?.querySelector("source");
      const sourceLink = getVideoUrl(isMobile, isMobile ? 3 : 6);
      if (source && source.src !== sourceLink) {
        source.src = sourceLink;
        video?.load();
      }
    }
  }, [isMobile]);
  useEffect(() => {
    setSource();
  }, [isMobile, setSource]);
  return (
    <div className={styles.videoBlock} ref={ref}>
      <div className={styles.container}>
        <video className={styles.videoContainer} autoPlay muted playsInline loop>
          <source type="video/mp4" />
				<track kind='captions' style={{display: 'none'}}></track>
        </video>
      </div>
    </div>
  );
};
